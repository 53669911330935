import Definition from "./Definition";
import { useState, useRef } from "react";

import { IconPlus, IconPencil } from "@tabler/icons";

export default function Meaning({
  meaning,
  userDb,
  index,
  word,
  read,
  addWordUserDb,
  readWordUserDb,
  ...props
}) {
  return (
    <div className="meaning flex flex-col items-start mb-3 transition pl-4 relative w-full">
      <div className="mr-2 mb-2">
        <span className=" text-yellow-100 italic text-xs rounded-full px-1 py-0.5 bg-yellow-400">
          {meaning.partOfSpeech}
        </span>
      </div>
      <div className="w-full space-y-6">
        {meaning.definitions.map((definition, definitionIndex) => {
          return (
            <Definition
              definition={definition}
              key={definition.definition}
              translation={readWordUserDb(word, index, definitionIndex)}
              addWordUserDb={addWordUserDb}
              index={definitionIndex}
              meaningIndex={index}
              word={word}
            />
          );
        })}
      </div>
    </div>
  );
}
