import { IconPencil, IconPlus } from "@tabler/icons";
import { useState, useRef } from "react";

export default function Definition({
  definition,
  index,
  meaningIndex,
  word,
  addWordUserDb,
  translation = null,
}) {
  const [adding, setAdding] = useState(false);
  const input = useRef();

  function addingPersianTranslation() {
    setAdding(true);
    setTimeout(() => {
      input.current.focus();
    }, 200);
  }

  function addTranslation(event) {
    if (event.key === "Enter") {
      addWordUserDb(word, event.target.value, meaningIndex, index);
      setAdding(false);
    }
    if (event.key === "Escape") {
      setAdding(false);
    }
  }

  return (
    <div className="w-full border-dashed group">
      <div className="text-left flex relative">
        {!translation ? (
          <div
            onClick={addingPersianTranslation}
            className="flex absolute top-0 right-0 sm:left-0 sm:right-auto sm:group-hover:opacity-100 sm:opacity-0 items-center justify-center p-0.5  bg-blue-200  hover:bg-blue-300 transition rounded-full cursor-pointer"
          >
            <IconPlus size={18} className="stroke-current text-gray-700" />
          </div>
        ) : null}

        <div className="mr-3 flex flex-col items-center">
          <div>🇺🇸</div>
        </div>
        <div>
          <span>{definition.definition}</span>
          <div>
            <span className="text-gray-400 font-thin italic">
              {definition.example}
            </span>
          </div>
        </div>
      </div>
      {translation && !adding ? (
        <div className="text-left flex relative">
          <div
            onClick={addingPersianTranslation}
            className="flex absolute top-0 right-0 sm:left-0 sm:right-auto sm:group-hover:opacity-100 sm:opacity-0 items-center justify-center p-0.5  bg-blue-200  hover:bg-blue-300 transition rounded-full cursor-pointer"
          >
            <IconPencil size={18} className="stroke-current text-gray-700" />
          </div>
          <div className="mr-3">🇮🇷</div>
          <div>
            <span className="font-persian">{translation}</span>
          </div>
        </div>
      ) : null}

      {adding ? (
        <div className="flex w-full mt-2 word-input relative">
          <div className="mr-3">🇮🇷</div>
          <div className="w-ful flex-grow">
            <input
              ref={input}
              onKeyUp={addTranslation}
              onBlur={() => setAdding(false)}
              type="text"
              defaultValue={translation}
              placeholder="معادل + اینتر"
              className="w-full font-persian bg-transparent outline-none text-left"
              style={{ direction: "rtl" }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
