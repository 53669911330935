import axios from "axios";
import { useState, useEffect, useRef } from "react";
import "./App.css";
import Word from "./components/Word";

function readDB() {
  let db = window.localStorage.getItem("listyad-db");
  if (db) {
    db = JSON.parse(db);
  } else {
    db = {};
  }
  return db;
}

function writeDB(db) {
  let stringified = JSON.stringify(db);
  window.localStorage.setItem("listyad-db", stringified);
}
function readUserDB() {
  let db = window.localStorage.getItem("listyad-user-db");
  if (db) {
    db = JSON.parse(db);
  } else {
    db = {};
  }
  return db;
}

function writeUserDB(db) {
  let stringified = JSON.stringify(db);
  window.localStorage.setItem("listyad-user-db", stringified);
}

function App() {
  const [db, setDb] = useState({});
  const [userDb, setUserDb] = useState({});
  const [word, setWord] = useState("");
  const [error, setError] = useState(null);
  const inputRef = useRef();

  useEffect(() => {
    setError(null);
  }, [word]);

  useEffect(() => {
    setDb(readDB());
    setUserDb(readUserDB());
    inputRef.current.focus();
  }, []);

  function editPersianDefinition(event) {
    console.log(event);
  }

  function addWordUserDb(word, translation, meaningIndex, definitionIndex) {
    let newDB;
    if (
      translation === "" &&
      userDb?.[word]?.[meaningIndex]?.[definitionIndex]
    ) {
      newDB = { ...userDb };
      delete newDB[word][meaningIndex][definitionIndex];
    }
    if (userDb[word]) {
      newDB = {
        ...userDb,
        [word]: {
          ...userDb[word],
          [meaningIndex]: {
            ...userDb[word][meaningIndex],
            [definitionIndex]: translation,
          },
        },
      };
    } else {
      newDB = {
        ...userDb,
        [word]: { [meaningIndex]: { [definitionIndex]: translation } },
      };
    }
    setUserDb(newDB);
    writeUserDB(newDB);
  }

  function readWordUserDb(word, meaningIndex, definitionIndex) {
    console.log(word, meaningIndex, definitionIndex);
    return userDb?.[word]?.[meaningIndex]?.[definitionIndex];
    // if (userDb[word] && userDb[word][meaningIndex]) {
    // }
    // return null;
  }

  async function submit(event) {
    event.preventDefault();
    let response;
    if (word === "") {
      setError("your word must be longer than 1 character!");
      return;
    }
    if (db[word]) {
      setError("this word currently added to your dictionary!");
      return;
    }

    try {
      response = await axios.get(
        `https://api.dictionaryapi.dev/api/v2/entries/en_US/${word}`
      );
      const newDB = { ...db, [response.data[0].word]: response.data[0] };
      setDb(newDB);
      writeDB(newDB);
      setWord("");
      setError(null);
    } catch (e) {
      setError("No Definitions Found");
    }
  }

  function deleteWord(word) {
    const confirm = window.confirm(`Deleting "${word}"`);
    if (!confirm) return;

    const newDB = { ...db };
    delete newDB[word];
    setDb(newDB);
    writeDB(newDB);
  }

  function editDefinition(word, meaningIndex, definitionIndex, newDefinition) {
    const newDB = { ...db };
    newDB[word].meanings[meaningIndex].definitions[definitionIndex].definition =
      newDefinition;
    setDb(newDB);
    writeDB(newDB);
  }

  function runIfEnterPressed(event) {
    if (event.key === "Enter") {
      submit(event);
    }
  }

  // console.log("db:", db, Object.entries(db));

  return (
    <div className="App mt-8 max-w-screen-md mx-auto px-4 text-center">
      <h1 className="text-xl my-4">
        List
        <span className="font-semibold text-blue-400">Yad</span>
      </h1>
      <form className="flex" onSubmit={submit} autoComplete="off">
        <input
          ref={inputRef}
          className="px-3 py-3 w-full text-4xl font-light outline-none border border-white focus:border-gray-300 border-dashed rounded-md"
          placeholder="word + enter"
          type="text"
          id="word"
          value={word}
          onChange={(e) => setWord(e.target.value)}
          onKeyUp={runIfEnterPressed}
        />
      </form>
      <div className="w-full rounded-md bg-red-100 text-red-500 text-lg mt-2">
        {error ? <div>{error}</div> : null}
      </div>

      <div className="mt-8">
        {Object.entries(db).length > 0 ? (
          Object.entries(db)
            .reverse()
            .map((word) => {
              return (
                <Word
                  word={word[1]}
                  key={word[0]}
                  readWordUserDb={readWordUserDb}
                  addWordUserDb={addWordUserDb}
                  editDefinition={editDefinition}
                  deleteWord={deleteWord}
                  editPersianDefinition={editPersianDefinition}
                />
              );
            })
        ) : (
          <p className="font-light italic text-4xl text-gray-300 text-left">
            There is no word in your dictionary yet!
            <br /> Please add some and then you can practice them.
          </p>
        )}
      </div>
      <footer className="text-gray-500 text-sm mt-8 py-4">
        created with ❤️ by{" "}
        <a
          className="font-semibold hover:text-blue-400"
          target="_blank"
          href="https://github.com/mkal1375"
          rel="noreferrer"
        >
          @mkal1375
        </a>
      </footer>
    </div>
  );
}

export default App;
