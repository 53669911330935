import { IconVolume, IconTrash } from "@tabler/icons";
import Meaning from "./Meaning";

export default function Word({
  word,
  deleteWord,
  editDefinition,
  editPersianDefinition,
  readWordUserDb,
  addWordUserDb,
  userDb,
  ...props
}) {
  console.log(word);
  async function play(url) {
    const audio = new Audio(url);
    audio.play();
  }
  return (
    <div
      className="flex flex-col items-start p-4 rounded-md border transition hover:border-gray-500 border-dashed mb-2"
      {...props}
    >
      <header className="flex justify-between w-full mb-4">
        <div>
          <div className="flex items-center">
            <span className="text-xl font-semibold">{word.word}</span>
            {word?.phonetics[0]?.audio ? (
              <span onClick={() => play(word.phonetics[0].audio)}>
                <IconVolume
                  size={20}
                  className="ml-2 stroke-current text-gray-400 hover:text-blue-500 cursor-pointer"
                />
              </span>
            ) : null}
          </div>
          <div className="font-light text-sm text-gray-400 text-left">
            {word?.phonetics[0].text}
          </div>
        </div>
        <div>
          <span
            onClick={() => deleteWord(word.word)}
            className="stroke-current text-gray-400 hover:text-red-400 cursor-pointer"
          >
            <IconTrash size={20} />
          </span>
        </div>
      </header>
      <main className="w-full">
        {word.meanings.map((meaning, meaningIndex) => (
          <Meaning
            meaning={meaning}
            word={word.word}
            readWordUserDb={readWordUserDb}
            addWordUserDb={addWordUserDb}
            index={meaningIndex}
            key={meaningIndex}
          />
        ))}
      </main>
    </div>
  );
}
